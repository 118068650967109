import { Link, graphql } from 'gatsby'
import { Seo } from '~/components'
import Arrow from '~/images/common_icon_arrow_r.svg'

const PageTemplate = ({ data }) => {
  const page = data.wpPage

  return (
    <>
      <Seo title={page.title} pageUrl={'pages/' + page.slug} />
      <div className="pageComponent">
        <article className="" itemScope itemType="http://schema.org/Article">
          <header className="titleComponent contentComponent">
            <h1 className="heading pageTitle">{page.title}</h1>
            <p>よくある質問と回答</p>
          </header>
          <nav className="contentComponent">
            <ul className="breadcrumbComponent mincho">
              <li>
                <Link to="/" itemProp="url">
                  TOP
                </Link>{' '}
                <span>
                  <Arrow />
                </span>
              </li>
              <li>{page.title}</li>
            </ul>
          </nav>
          <div className="contentComponent">
            <div dangerouslySetInnerHTML={{ __html: page.content }} />
          </div>
        </article>
      </div>
    </>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      slug
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
